















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../../helper/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { Validation, validationMixin } from 'vuelidate';
import { email, numeric, required, maxLength, minLength, requiredIf } from 'vuelidate/lib/validators';
import Customer from '@/models/Customer';
import { namespace } from 'vuex-class';
import { CountryStorage } from '@/misc/CountryStorage';
import Company from '@/models/Company';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import Address from '@/models/Address';
import { LCircle, LMap, LMarker, LTileLayer } from 'vue2-leaflet';
import AddressWithMapComponent from '@/components/shared/AddressWithMap.component.vue';
import ContactPerson from '@/models/ContactPerson';


const CustomerStore = namespace('customer');
const CompanyStore = namespace('company');


@Component({
  components: {
    RJSelect, RJTextField, LMap,
    AddressWithMapComponent,
    LTileLayer,
    LMarker,
    LCircle,
    LabelComponent: () => import('@/components/shared/Label.component.vue'),
  },
  mixins: [validationMixin],
  validations: {
    customerCopy: {
      name: { required },
      address: {
        street: { required },
        houseNo: { required, maxLength: maxLength(15) },
        postalCode: { required, numeric, maxLength: maxLength(5) },
        city: { required },
        country: { required },
      },
      // TODO: validate invoiceAddress
      // invoiceAddress: {
      //   required: requiredIf((vm) => !!vm),
      //   street: {required: requiredIf((vm) => !!vm)},
      //   houseNo: {required: requiredIf((vm) => !!vm), maxLength: maxLength(15)},
      //   postalCode: {required: requiredIf((vm) => !!vm), numeric, maxLength: maxLength(5)},
      //   city: {required: requiredIf((vm) => !!vm)},
      //   country: {required: requiredIf((vm) => !!vm)},
      // },
      contactPersons: {
        $each: {
          firstName: { required },
          lastName: { required },
          email: {
            required: requiredIf((vm: ContactPerson) => !vm.phone),
            email,
          },
          phone: {
            required: requiredIf((vm: ContactPerson) => !vm.email),
            minLength: minLength(3),
          },
        },
      },
    },
  },
})
export default class CustomerManageContentComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * This Prop is for enabling the router, to route to the customersDetailsView if wanted.
   * Introduced for the JobManagementComponent at the CalendarCalendarComponent, so as not to route after creating
   * a customer
   */
  @Prop({ default: () => true })
  public enableRouter!: boolean;

  @Prop({ default: () => new Customer() })
  public customer!: Customer;

  private vuelidate!: Validation;

  /**
   * A copy of the customer to work with, during the manage-dialog
   */
  public customerCopy: Customer = new Customer();
  public showComponent: boolean = false;

  public submitted: boolean = false;
  public hasInvoiceAddress: boolean = false;

  @CustomerStore.Action('createCustomerAction')
  public createCustomerAction!: (customer: Customer) => Promise<Customer>;
  @CustomerStore.Action('editCustomerAction')
  public editCustomerAction!: (customer: Customer) => Promise<Customer>;
  @CustomerStore.Action('deleteCustomerAction')
  public deleteCustomerAction!: (customer: Customer) => Promise<Customer>;

  @CompanyStore.Getter('activeCompany')
  private company!: Company;

  /**
   * Countries for country select
   */
  get countries() {
    return CountryStorage;
  }

  private onInput(data: { address: Address, vuelidate: any }) {
    this.customerCopy.address = data.address;
    this.vuelidate = data.vuelidate;
  }

  private validateAddress() {
    this.vuelidate.$touch();
  }

  private addContactPerson() {
    this.customerCopy.contactPersons.push(new ContactPerson());
  }

  private removeContactPerson(index: number) {
    this.customerCopy.contactPersons.splice(index, 1);
  }

  /**
   * Event handler that saves the edition or the creation of user object
   */
  public async onSubmit(event: Event) {
    event.preventDefault();

    if (this.submitted && !this.$v.customerCopy!.$invalid) {
      return;
    }
    this.submitted = true;

    if (!this.hasInvoiceAddress) {
      this.customerCopy.invoiceAddress = null;
    }

    this.$v.customerCopy!.$touch();
    this.validateAddress();

    if (this.$v.customerCopy!.$invalid || this.vuelidate.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of an css selector of a vuetify property is not always safe
    } else {
      this.customerCopy.contactPersons?.forEach((cp) => {
        // label component returns object instead of defined value, so set the label to the name of the labelObject
        if (cp.label && typeof cp.label === 'object') {
          cp.label = (cp.label as any).name;
        }
      });
      try {
        if (this.customerCopy && this.customerCopy.companyId) {
          this.customerCopy = await this.editCustomerAction(this.customerCopy);
          this.$notifySuccessSimplified('CUSTOMER.NOTIFICATIONS.EDIT');
          this.$emit('exitModal', this.customerCopy);
        } else {
          this.customerCopy!.companyId = this.company.id;
          const createdCustomer = await this.createCustomerAction(this.customerCopy!);
          this.$notifySuccessSimplified('CUSTOMER.NOTIFICATIONS.CREATE');
          // if wanted, go to customerDashboard, otherwise not. If you are at the jobManagementComponent
          // you dont want to route to the dashboard after creating a customer
          if (this.enableRouter) {
            await this.$router.push({
              name: 'customerDashboard',
              params: {
                companyId: this.company.id,
                customerId: createdCustomer.id!,
              },
            });
          }
          this.$emit('exitModal', createdCustomer);
        }
      } catch (e: any) {
        if (this.$te(`CUSTOMER.NOTIFICATIONS.${e.status}.TITLE`)) {
          this.$notifyErrorSimplified(`CUSTOMER.NOTIFICATIONS.${e.status}`);
        } else {
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      }
    }
    this.submitted = false;
  }

  public cancelButtonClicked() {
    this.$emit('exitModal', null);
  }

  private customerAddress: Address = new Address();

  @Watch('customer', { immediate: true })
  private onCustomerChange() {
    this.customerCopy = this.customer.copy() as Customer;
    this.customerAddress = this.customerCopy.address!;
    this.showComponent = true;
    if (this.customerCopy.invoiceAddress) {
      this.hasInvoiceAddress = true;
    }
  }

  @Watch('hasInvoiceAddress')
  private watchInvoice(value: boolean) {
    if (value && !this.customerCopy.invoiceAddress) {
      this.customerCopy.invoiceAddress = new Address();
    }
  }
}
